import { initYandexMetrika } from "yandex-metrika-vue3";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  const UseLocalStore = useLocalStore();
  const { cookiesSettings } = storeToRefs(UseLocalStore);
  if (!cookiesSettings.value.allCookies) {
    return;
  }
  const router = useRouter();
  // @ts-ignore-next-line
  nuxtApp.vueApp.use(initYandexMetrika, {
    id: "97485690",
    router: router,
    env: process.env.NODE_ENV,
  });
});
