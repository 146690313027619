export const getWsUrl = (
  path: string,
  params: Record<string, unknown> = {}
) => {
  let url = `${document.location.origin}/socket${path}`;
  if (url.indexOf("https") !== -1) {
    url = url.replace("https", "wss");
  } else {
    url = url.replace("http", "ws");
  }
  Object.keys(params).forEach((key, i) => {
    url += i === 0 ? "?" : "&";
    url += `${key}=${params[key]}`;
  });
  return url;
};

export const getReqUrl = (
  path: string,
  params: Record<string, unknown> = {}
) => {
  let url = `/api${path}`;
  Object.keys(params).forEach((key, i) => {
    url += i === 0 ? "?" : "&";
    url += `${key}=${params[key]}`;
  });
  return url;
};

const getRequestHeaders = (passedHeaders: Record<string, unknown> = {}) => {
  const headers = {
    ...passedHeaders,
  };
  const { token } = useAuth();

  if (token.value) headers.authorization = token.value;

  if (!headers["content-type"]) {
    headers["content-type"] = "application/json";
  }

  return headers as HeadersInit;
};
type Method =
  | "GET"
  | "HEAD"
  | "PATCH"
  | "POST"
  | "PUT"
  | "DELETE"
  | "CONNECT"
  | "OPTIONS"
  | "TRACE";
interface OptionsI {
  params?: Record<string, any>;
  headers?: Record<string, any>;
  method?: Method;
  body?: any;
  signal?: AbortSignal;
}

export const request = async <T>(path: string, options: OptionsI = {}) => {
  const URL = getReqUrl(path, options.params);
  const res = await $fetch<T>(URL, {
    headers: getRequestHeaders(options.headers),
    method: options.method || "GET",
    body: options.body,
    signal: options.signal,
  });

  return res;
};
