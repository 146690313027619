import { fetchCurrencies, fetchAvailablePairs } from "@/api";
import type { Currency, AvailablePairs } from "@/shared/types";
import { getSympol } from "@/shared/utils";

export const useCurrencyStore = defineStore("currencies", () => {
  const currencies = ref<Currency[]>([]);
  const availablePairs = reactive<AvailablePairs>({
    buyDirections: {},
    sellDirections: {},
  });

  const cryptoCurrencies = computed(() => {
    const cryptoParent = currencies.value.find(
      (c) => c.name === "Crypto" && c.parent_id === 0
    );
    return (
      currencies.value.filter((c) => c.parent_id === cryptoParent?.bpc_id) || []
    );
  });

  const fiatCurrencies = computed(() => {
    const fiatParent = currencies.value.find(
      (c) => c.name === "Fiat" && c.parent_id === 0
    );
    return (
      currencies.value.filter((c) => c.parent_id === fiatParent?.bpc_id) || []
    );
  });

  const loading = ref(false);

  const getCurrencies = async (silent = false) => {
    if (!silent) {
      loading.value = true;
    }
    const res = await fetchCurrencies();
    currencies.value = res;
    if (!silent) {
      loading.value = false;
    }
  };

  const getSympolById = (id: number | string) => {
    let type: string | number | undefined = id;
    if (typeof id === "number") {
      type = currencies.value.find?.((c) => c.bpc_id === id)?.name;
    }
    return getSympol(type as string);
  };

  const getAvailablePairs = async () => {
    const res = await fetchAvailablePairs();
    availablePairs.buyDirections = res.buyDirections;
    availablePairs.sellDirections = res.sellDirections;
    return res;
  };

  return {
    currencies,
    cryptoCurrencies,
    fiatCurrencies,
    loading,
    availablePairs,
    getCurrencies,
    getSympolById,
    getAvailablePairs,
  };
});
