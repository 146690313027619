export default defineNuxtPlugin((nuxtApp) => {
  let subscribeUser;
  if ("serviceWorker" in navigator && "PushManager" in window) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(function (registration) {
        return registration;
      })
      .then(function () {
        return navigator.serviceWorker.ready;
      })
      .then(function () {})
      .catch(function () {});

    function getUrl(path: string) {
      return `${location.protocol}//${location.host}${path}`;
    }
    subscribeUser = async (data?: any) => {
      try {
        const registration = await navigator.serviceWorker.ready;

        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            useRuntimeConfig().public.vapidPublicKey as string
          ),
        };

        const pushSubscription = await registration.pushManager.subscribe(
          subscribeOptions
        );

        await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscription: pushSubscription,
            data: {
              ...data,
              url: data.url ? getUrl(data.url) : null,
            },
          }),
        });
      } catch (error) {}
    };

    nuxtApp.provide("subscribeUser", subscribeUser);
  } else {
  }

  function urlBase64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
});
