import { request } from "@/api";
import type { Currency, AvailablePairs } from "@/shared/types";

export const fetchCurrencies = async () => {
  return request<Currency[]>("/currencies");
};

export const fetchAvailablePairs = async () => {
  return request<AvailablePairs>("/currencies/available-pairs");
};
