<script setup lang="ts">
import type { NuxtError } from "nuxt/app";
defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});
const errorCont = ref();
const handleError = () => clearError({ redirect: "/" });
const runAnimation = () => {
  const imageContainer = errorCont.value.querySelector(".image-cont");
  imageContainer.style.clipPath = "circle(150% at 50% 50%)";
};
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="error-container" ref="errorCont">
        <el-row :gutter="20">
          <el-col :span="24" :lg="12">
            <template v-if="error.statusCode === 404">
              <h1 class="error-code">Lost in cryptospace?</h1>
              <h2>Error {{ error.statusCode }}</h2>
              <p class="error-message">
                Looks like that page doesn't exist - please check the URL and
                try again
              </p>
            </template>
            <template v-else>
              <h1 class="error-code danger">Oops!</h1>
              <h2>Error {{ error.statusCode }}</h2>
              <p class="error-message">
                The page you are looking for seems to be missing
              </p>
            </template>
            <ElButton type="primary" size="large" @click="handleError">
              Go back home
            </ElButton>
          </el-col>
          <el-col :span="24" :lg="12">
            <el-image
              lazy
              class="image-cont"
              src="/img/404.webp"
              @load="runAnimation"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
}

.image-cont {
  overflow: hidden;
  border-radius: 50%;
  animation: waveAnimation;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: inset 0 0 10px #000, inset 10px 10px 20px #0005;
  }

  &:hover :deep(.el-image__inner) {
    transform: scale(1);
    animation-play-state: paused;
  }

  :deep(.el-image__inner) {
    width: 100%;
    height: auto;
    animation: waveAnimation 10s infinite;
    transform: scale(1.1);
  }

  @media screen and (max-width: 1200px) {
    margin-top: 30px;
    width: 350px;
  }
}

@keyframes waveAnimation {
  0% {
    transform: translateY(0) scale(1.1);
  }
  50% {
    transform: translateY(-20px) scale(1.12);
  }
  100% {
    transform: translateY(0) scale(1.1);
  }
}
.error-code {
  &.danger {
    color: var(--el-color-danger);
  }
  font-size: 5em;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 650px) {
    font-size: 3em;
  }
}

.error-message {
  font-size: 1.5em;
  margin: 20px 0;
  opacity: 0.8;
}
</style>
