import { fetchInit } from "@/api";
import type { InitData } from "@/shared/types";

export const useInitStore = defineStore("init", () => {
  const initData = ref<InitData | null>(null);

  const getInitData = async () => {
    const res = await fetchInit();
    initData.value = res;
  };

  return { initData, getInitData };
});
