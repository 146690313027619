import { fetchRates } from "@/api";
import type { Rates } from "@/shared/types";

export const useRatesStore = defineStore("rates", () => {
  const rates = ref<Rates>({});
  const refreshDate = ref<string | null>(null);
  const loading = ref(false);

  const getRates = async (silent = false) => {
    if (!silent) {
      loading.value = true;
    }
    const res = await fetchRates();
    rates.value = (res?.CRYPTO?.currencies || res?.currencies || res) as any;
    refreshDate.value = (res?.CRYPTO?.refreshDate ||
      res?.refreshDate ||
      null) as any;
    if (!silent) {
      loading.value = false;
    }
  };

  return { rates, refreshDate, loading, getRates };
});
