export const satoshiToBTC = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const satoshiRate = 100000000;
  return toBaseUnit ? amount / satoshiRate : amount * satoshiRate;
};

export const weiToETH = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const weiRate = 1000000000;
  return toBaseUnit ? amount / weiRate : amount * weiRate;
};

export const dropsToXRP = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const dropsRate = 1000000;
  return toBaseUnit ? amount / dropsRate : amount * dropsRate;
};

export const litoshiToLTC = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const litoshiRate = 100000000;
  return toBaseUnit ? amount / litoshiRate : amount * litoshiRate;
};

export const centsToUSDT = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const centsRate = 1000000;
  return toBaseUnit ? amount / centsRate : amount * centsRate;
};

export const microneroToXMR = (
  amount: number,
  toBaseUnit: boolean = false
): number => {
  const microneroRate = 1000000;
  return toBaseUnit ? amount / microneroRate : amount * microneroRate;
};

export const cryptoConvert = (
  amount: number,
  currency: string,
  toBaseUnit: boolean = false
) => {
  switch (currency) {
    case "BTC":
      return satoshiToBTC(amount, toBaseUnit);
    case "USDT":
      return centsToUSDT(amount, toBaseUnit);
    case "ETH":
      return weiToETH(amount, toBaseUnit);
    case "XRP":
      return dropsToXRP(amount, toBaseUnit);
    case "LTC":
      return litoshiToLTC(amount, toBaseUnit);
    case "XMR":
      return microneroToXMR(amount, toBaseUnit);
    default:
      return amount;
  }
};

export const fiatConvert = (
  amount: number,
  toBaseUnit: boolean = false,
  ratePassed?: number
): string => {
  const fiatRate = ratePassed || 100;
  const fixedCount = fiatRate.toString().length - 1;
  return (toBaseUnit ? amount / fiatRate : amount * fiatRate).toFixed(
    fixedCount
  );
};

export const getSympol = (currency: string) => {
  switch (currency) {
    case "RUB":
      return "₽";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    default:
      return currency;
  }
};
