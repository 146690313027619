import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Jozdw60ZsE from "/usr/src/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_AOuQ1DYzjk from "/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_OVoKJro5pc from "/usr/src/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/usr/src/app/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/usr/src/app/.nuxt/element-plus-injection.plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/src/app/.nuxt/unocss.mjs";
import ssr_plugin_B4ptqVdIfe from "/usr/src/app/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_authRenew_client_6lt2h2r9iZ from "/usr/src/app/plugins/01.authRenew.client.ts";
import _02_rates_client_NyaNcfswIP from "/usr/src/app/plugins/02.rates.client.ts";
import _03_currencies_client_F6hhicNiXX from "/usr/src/app/plugins/03.currencies.client.ts";
import chart_client_qQ92gke75l from "/usr/src/app/plugins/chart.client.ts";
import push_notifications_client_9sl3USyx58 from "/usr/src/app/plugins/push-notifications.client.ts";
import vue_flags_eVvGHLkGgt from "/usr/src/app/plugins/vue-flags.ts";
import yandex_metrika_client_MiNHrQhx1f from "/usr/src/app/plugins/yandex-metrika.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_Jozdw60ZsE,
  plugin_AOuQ1DYzjk,
  plugin_client_OVoKJro5pc,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  unocss_MzCDxu9LMj,
  ssr_plugin_B4ptqVdIfe,
  _00_init_client_77O4tQBcSo,
  _01_authRenew_client_6lt2h2r9iZ,
  _02_rates_client_NyaNcfswIP,
  _03_currencies_client_F6hhicNiXX,
  chart_client_qQ92gke75l,
  push_notifications_client_9sl3USyx58,
  vue_flags_eVvGHLkGgt,
  yandex_metrika_client_MiNHrQhx1f
]