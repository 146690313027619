export const useLocalStore = defineStore("localStore", () => {
  const notificationSettings = useLocalStorage("notificationSettings", {
    web: { order: true, chat: true },
    system: { order: true, chat: true },
  });

  const cookiesSettings = useLocalStorage("cookiesSettings", {
    settedUp: false,
    allCookies: true,
  });

  const tfaAlert = useLocalStorage<Record<string, null | number>>(
    "TFAWarning",
    {
      date: null,
      userId: null,
    }
  );

  return {
    notificationSettings,
    cookiesSettings,
    tfaAlert,
  };
});
